
.general-info__education-dropdown{
  width: 100% !important;
}
.number-input__income{
  width: 50% !important;
}

.general-info__income-input{
  width: 30%;
  @media(max-width: $mobile-breakpoint ){
    width: 55%;
  }
}

.general-info__input{
  display: flex;
  flex-direction: column;
}