.medicalOverview__container{
    padding: 4% 30%;
    @media only screen and (max-width: 900px) {
      padding: 4%;
    }  
}

.medicalOverview__title{
    display:flex;
    align-items: center;
    text-align: center;
}
.medicalOverview__input{
    display: flex;
    flex-direction: column;

}
.medicalOverview__button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center !important;
    width: 400px !important;
    @media(max-width: $tablet-breakpoint){
        width: 100% !important;
      } 
}

.medicalOverview__date{
    display: flex;
    flex-direction: column;
    width: 30% !important;
    @media(max-width: $tablet-breakpoint){
        width: 95% !important;
    }
}
.medicalOverview__question{
  margin-top: 2%;
  display: flex;
  flex-direction: column;

}
.medicalOverview__input{
  display: block;
}
.medicalOverview__radio{
  display: flex;
  flex-direction: column;
}