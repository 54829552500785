.activation-tutorials__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 0%;
  @media only screen and (max-width: 900px) {
    margin-top: 4%;
    
  }
}

.activation-tutorials__threshold-input-one{
  align-items: center;
  // width: 500px;
  display: flex;
  margin-top: 2%;
  margin-bottom: 7%;
  flex-direction: column;
  
}
.activation-tutorials__threshold-input-two{
  align-items: center;
  display: flex;
  margin-bottom: 7%;
  flex-direction: column;
  
}

.activation-tutorials__threshold-reading{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.activation-tutorials__threshold-button{
  display: flex;
  flex-direction: column;
  align-items: center!important;
  justify-content: center!important;
  align-content: center;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
}

.activation-tutorials__warning-text{
  display: flex;
  align-items: right;
  flex-direction: column;
  justify-content: right;
  font-size: 15px;
  padding-bottom: 16px;
  // @media(max-width: $mobile-breakpoint) {
  //   width: 45%;
  //  };
}

.activation-tutorials__spinner{
  display: flex;
  flex-direction: column;
}

.activation-tutorials__titles{
  justify-items: left;
  text-align: left;
  width: 90%;
  font-weight: 700;
}
.activation-tutorials__vital-feedback{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #1882A5;
  font-size: 36px;
  font-weight: 700;
}

.activation-tutorials__text{
  align-items: center;
  flex-direction: column;
  display: flex;
  padding-top: 16px;
  text-align: center;
  @media(max-width: $mobile-breakpoint) {
    width: 100%;
    align-items: center;
   };
}