.responsive-image {
    position: relative;
    width: 50% !important;
    height: 50% !important;
    @media(max-width: $mobile-breakpoint){
      width:80% !important;
      height:80% !important;
    }
  }
  
