.layout__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%;

  @media (max-width: $tablet-breakpoint) {
    justify-content: center;
  }
}

.layout__layout {
  min-height: 100vh !important;
}

.layout__sider {
  background-color: white;
  padding: 128px 1% 0% 1%;
  @media (max-width: $tablet-breakpoint) {display: none}
}

.layout__content {
  display: flex;
  flex-direction: column;
  padding: 2%;
  margin-top: 70px;
}

.layout__content-box {
  background-color: white;
  flex: 1;
  padding: 2%;
}

.layout__navigation-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}

.layout__logo-text {
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  white-space: nowrap;
  font-size: 30px!important;

  @media (max-width: $tablet-breakpoint) {
    font-size: 1.4rem !important;
  }
}

.layout__logged-in-text {
  color: white !important;
  padding: 2 !important;
  margin: 0 !important;
  // left: 1%;

  @media (max-width: $tablet-breakpoint) {
    left: 10%;
  }
}

.introduction__container {
  padding: 4% 30%;
  @media only screen and (max-width: 900px) {
    padding: 4%;
    
  }
}

.layout__logo{
  display: flex;
  flex-direction: row;
  align-items: center;
}