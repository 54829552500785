.activation-tutorials__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 0%; }
  @media only screen and (max-width: 900px) {
    .activation-tutorials__container {
      margin-top: 4%; } }

.activation-tutorials__threshold-input-one {
  align-items: center;
  display: flex;
  margin-top: 2%;
  margin-bottom: 7%;
  flex-direction: column; }

.activation-tutorials__threshold-input-two {
  align-items: center;
  display: flex;
  margin-bottom: 7%;
  flex-direction: column; }

.activation-tutorials__threshold-reading {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row; }

.activation-tutorials__threshold-button {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;
  align-content: center;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px; }

.activation-tutorials__warning-text {
  display: flex;
  align-items: right;
  flex-direction: column;
  justify-content: right;
  font-size: 15px;
  padding-bottom: 16px; }

.activation-tutorials__spinner {
  display: flex;
  flex-direction: column; }

.activation-tutorials__titles {
  justify-items: left;
  text-align: left;
  width: 90%;
  font-weight: 700; }

.activation-tutorials__vital-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #1882A5;
  font-size: 36px;
  font-weight: 700; }

.activation-tutorials__text {
  align-items: center;
  flex-direction: column;
  display: flex;
  padding-top: 16px;
  text-align: center; }
  @media (max-width: 450px) {
    .activation-tutorials__text {
      width: 100%;
      align-items: center; } }

.layout__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 1;
  width: 100%; }
  @media (max-width: 768px) {
    .layout__header {
      justify-content: center; } }

.layout__layout {
  min-height: 100vh !important; }

.layout__sider {
  background-color: white;
  padding: 128px 1% 0% 1%; }
  @media (max-width: 768px) {
    .layout__sider {
      display: none; } }

.layout__content {
  display: flex;
  flex-direction: column;
  padding: 2%;
  margin-top: 70px; }

.layout__content-box {
  background-color: white;
  flex: 1;
  padding: 2%; }

.layout__navigation-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 15px; }

.layout__logo-text {
  color: white !important;
  padding: 0 !important;
  margin: 0 !important;
  white-space: nowrap;
  font-size: 30px !important; }
  @media (max-width: 768px) {
    .layout__logo-text {
      font-size: 1.4rem !important; } }

.layout__logged-in-text {
  color: white !important;
  padding: 2 !important;
  margin: 0 !important; }
  @media (max-width: 768px) {
    .layout__logged-in-text {
      left: 10%; } }

.introduction__container {
  padding: 4% 30%; }
  @media only screen and (max-width: 900px) {
    .introduction__container {
      padding: 4%; } }

.layout__logo {
  display: flex;
  flex-direction: row;
  align-items: center; }

.pro-health-or-addison__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 300px auto auto;
  align-items: end;
  grid-gap: 25px;
  grid-template-areas: '. title title .' '. prohealth_button addison_button . ' '. . . .'; }
  @media (max-width: 768px) {
    .pro-health-or-addison__container {
      grid-template-areas: 'title title title title' 'prohealth_button  prohealth_button prohealth_button prohealth_button' 'addison_button addison_button addison_button addison_button'; } }

.addison__button {
  grid-area: addison_button; }

.pro-health__button {
  grid-area: prohealth_button; }

.pro-health-or-addison__title {
  grid-area: title;
  text-align: center; }

.activation__search-container {
  display: grid;
  grid-template-columns: 1fr 15% 15% 1fr;
  grid-template-rows: 5% auto auto auto auto auto auto auto auto;
  grid-gap: 20px;
  text-align: center;
  grid-template-areas: '. . . .' '. title title . ' '. activation activation .' '. select select .' '. input input .' '. button button .' '. info info .' '. info info .'; }
  @media (max-width: 768px) {
    .activation__search-container {
      grid-template-columns: auto 35% 35% auto;
      grid-template-rows: 0% auto auto auto auto auto auto auto auto; } }

.activation__search-title {
  grid-area: title; }

.activation__search-select {
  grid-area: select; }

.activation__search-input {
  grid-area: input; }

.activation__search-ready-button {
  grid-area: button; }

.activation__search-patientinfo {
  grid-area: info; }

.rapid-activation__title {
  display: flex;
  margin-top: 5%; }

.rapid-activation__spin {
  margin-top: 10%; }

.rapid-activation__container {
  display: flex;
  align-items: center;
  flex-direction: column; }

.pro-health__image {
  position: relative;
  width: 20%;
  height: 20%; }
  @media (max-width: 768px) {
    .pro-health__image {
      width: 50%;
      height: 20%; } }

.emergency-icon__image {
  width: '100%';
  padding-top: '10%';
  max-width: '400px'; }
  @media only screen and (max-width: 900px) {
    .emergency-icon__image {
      padding-top: '8%'; } }

.test-signal__container {
  display: flex;
  align-items: center;
  flex-direction: column; }

.vitals__layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 10%; }
  @media (max-width: 768px) {
    .vitals__layout {
      margin-top: 30%; } }
  @media (max-width: 450px) {
    .vitals__layout {
      margin-top: 20%; } }

.vitals__buttons {
  margin-top: 30px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-left: 6%; }
  @media (max-width: 768px) {
    .vitals__buttons {
      width: 40%;
      padding-left: 0%;
      flex-direction: column; } }
  @media (max-width: 450px) {
    .vitals__buttons {
      width: 50%;
      padding-left: 0%; } }

.complete-activation__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 4%;
  margin-bottom: 3%; }

.complete-activation__button {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-content: center; }

.Create-Addison-User__Layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 8%; }
  @media (max-width: 768px) {
    .Create-Addison-User__Layout {
      margin-top: 10%; } }
  @media (max-width: 450px) {
    .Create-Addison-User__Layout {
      margin-top: 20%; } }

.Create-Addison-User__Button {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-content: right; }

.Create-Addison-User__Title {
  display: flex;
  align-items: left;
  flex-direction: column; }

.Create-Addison-User__Oops {
  display: flex;
  align-items: left;
  flex-direction: column; }
  @media (max-width: 768px) {
    .Create-Addison-User__Oops {
      align-items: center;
      font-size: 25px !important;
      align-content: center;
      margin-left: auto;
      margin-right: auto; } }

.Create-Addison-User__image {
  position: relative;
  width: 12%;
  height: 12%; }
  @media (max-width: 768px) {
    .Create-Addison-User__image {
      width: 45%;
      height: 15%; } }

.responsive-image {
  position: relative;
  width: 50% !important;
  height: 50% !important; }
  @media (max-width: 450px) {
    .responsive-image {
      width: 80% !important;
      height: 80% !important; } }

.general-info__education-dropdown {
  width: 100% !important; }

.number-input__income {
  width: 50% !important; }

.general-info__income-input {
  width: 30%; }
  @media (max-width: 450px) {
    .general-info__income-input {
      width: 55%; } }

.general-info__input {
  display: flex;
  flex-direction: column; }

.general-info__education-dropdown {
  width: 100% !important; }

.general-health__minus-button {
  display: inline-block; }

.general-health__plus-button {
  display: inline-block; }

.general-health__substance-field {
  display: inline-block; }

.general-health-side-button {
  margin-left: 2%;
  margin-bottom: 3%; }

.general-health-center-button {
  align-items: left;
  margin-bottom: 5%; }

.general-health-left-button {
  margin-left: 0%;
  margin-right: 59%;
  margin-bottom: 10%; }

.general-health-container {
  display: flex;
  flex-direction: row; }

.general-health__question-group {
  margin-bottom: 7%; }

.general-health__radio-group {
  flex-direction: row; }

.general_health__input {
  display: flex;
  flex-direction: column; }

.general-info__education-dropdown {
  width: 100% !important; }

.provider-side-button {
  margin-left: 2%;
  margin-bottom: 3%; }

.provider-center-button {
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 3%;
  margin-top: 5%; }
  @media (max-width: 768px) {
    .provider-center-button {
      margin: 10%; } }

.provider-left-button {
  margin-left: 0%;
  margin-right: 59%;
  margin-bottom: 10%; }

.provider-container {
  display: flex;
  flex-direction: column; }

.provider__input-field {
  width: 500px; }
  @media (max-width: 768px) {
    .provider__input-field {
      width: 95%; } }

.general-info__education-dropdown {
  width: 100% !important; }

.medical-history-side-button {
  margin-left: 2%;
  margin-bottom: 3%; }

.medical-history-container {
  display: flex;
  flex-direction: row; }

.addison-questions__text {
  text-align: left; }

.addison-contacts__text {
  grid-area: title;
  text-align: center; }

.emergency-contacts__yes-no-radio {
  display: 'block';
  height: '30px';
  line-height: '30px'; }

.general-info__education-dropdown {
  width: 100% !important; }

.number-input__income {
  width: 50% !important; }

.addison-questions__text {
  text-align: left; }

.addison-contacts__text {
  grid-area: title;
  text-align: center; }

.emergency-contacts__yes-no-radio {
  display: 'block';
  height: '30px';
  line-height: '30px'; }

.wellbeing__question {
  margin-top: 5%; }

.medicalOverview__container {
  padding: 4% 30%; }
  @media only screen and (max-width: 900px) {
    .medicalOverview__container {
      padding: 4%; } }

.medicalOverview__title {
  display: flex;
  align-items: center;
  text-align: center; }

.medicalOverview__input {
  display: flex;
  flex-direction: column; }

.medicalOverview__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center !important;
  width: 400px !important; }
  @media (max-width: 768px) {
    .medicalOverview__button {
      width: 100% !important; } }

.medicalOverview__date {
  display: flex;
  flex-direction: column;
  width: 30% !important; }
  @media (max-width: 768px) {
    .medicalOverview__date {
      width: 95% !important; } }

.medicalOverview__question {
  margin-top: 2%;
  display: flex;
  flex-direction: column; }

.medicalOverview__input {
  display: block; }

.medicalOverview__radio {
  display: flex;
  flex-direction: column; }

.question {
  display: flex;
  flex-direction: column; }

.question__reminder {
  display: flex;
  flex-direction: column;
  align-items: center; }

.input {
  margin-bottom: 5%;
  margin-top: .25%;
  display: flex;
  flex-direction: column; }

.side-input-button {
  margin-left: 1%; }

.medication-center-button {
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 3%;
  margin-top: 5%;
  height: 40px; }
  @media (max-width: 450px) {
    .medication-center-button {
      margin-right: 12%; } }

.medication-left-button {
  display: flex;
  flex-direction: row !important;
  text-align: center;
  align-items: center;
  height: 30px !important;
  width: 40%;
  margin-bottom: 5%;
  margin-right: 70%; }
  @media screen and (max-width: 1250px) {
    .medication-left-button {
      width: 45%; } }
  @media screen and (max-width: 1100px) {
    .medication-left-button {
      width: 50%; } }
  @media screen and (max-width: 1000px) {
    .medication-left-button {
      width: 55%; } }
  @media screen and (max-width: 900px) {
    .medication-left-button {
      width: 30%; } }
  @media screen and (max-width: 720px) {
    .medication-left-button {
      width: 35%; } }
  @media (max-width: 450px) {
    .medication-left-button {
      margin-right: 60%;
      width: 60%; } }

.medication-remove-button {
  margin-left: 2%; }

.medications-container {
  display: flex;
  flex-direction: column; }

.medications__medication-inputs {
  width: 600px; }
  @media (max-width: 450px) {
    .medications__medication-inputs {
      width: 95%; } }

.medications__reminder-buttons {
  display: flex;
  align-content: center;
  margin-left: 5%;
  margin-top: 3%; }
  @media (max-width: 450px) {
    .medications__reminder-buttons {
      width: 95%;
      display: flex;
      justify-content: space-between;
      flex-direction: column; } }

.medications__reminder-button-Add {
  height: 40px !important;
  width: 400px !important;
  margin-right: 5%; }
  @media (max-width: 450px) {
    .medications__reminder-button-Add {
      width: 70% !important;
      font-size: 16px !important;
      height: 30% !important;
      margin-bottom: 2%; } }

.medications__vital-type {
  display: flex;
  flex-direction: column;
  width: 175px !important; }

.medications__time-input {
  display: flex;
  flex-direction: column; }

.medications__radio-group {
  flex-direction: row; }
