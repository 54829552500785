
.general-info__education-dropdown{
  width: 100% !important;
}
.provider-side-button {
  margin-left: 2%;
  margin-bottom: 3%;
}
.provider-center-button {
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 3%;
  margin-top: 5%;
  @media (max-width: $tablet-breakpoint){
    margin: 10%;
  }
}
.provider-left-button {
  margin-left: 0%;
  margin-right: 59%;
  margin-bottom: 10%;
}
.provider-container {
  display: flex;
  flex-direction: column;
}
.provider__input-field{
  width: 500px;
  @media (max-width: $tablet-breakpoint){
    width: 95%; 
  }
}