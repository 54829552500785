.vitals__layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
   @media(max-width: $tablet-breakpoint){
    margin-top: 30%
  }
  @media(max-width: $mobile-breakpoint){
    margin-top: 20%
  }
  
  
}
.vitals__buttons{
  margin-top: 30px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-left: 6%;
  @media(max-width: $tablet-breakpoint){
    width:40%;
    padding-left: 0%;
    flex-direction: column;
  }
  @media(max-width: $mobile-breakpoint){
    width:50%;
    padding-left: 0%;
  }
}