.complete-activation__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 4%;
  margin-bottom: 3%;
}
.complete-activation__button {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-content: center;
}