.activation__search-container {
  display: grid;
  grid-template-columns: 1fr 15% 15% 1fr ;
  grid-template-rows: 5% auto auto auto auto auto auto auto auto;
  grid-gap: 20px;
  text-align: center;
  @media(max-width: $tablet-breakpoint) {
    grid-template-columns: auto 35% 35% auto; 
    grid-template-rows: 0% auto auto auto auto auto auto auto auto
  };

  grid-template-areas:
  '. . . .'
  '. title title . '
  '. activation activation .'
  '. select select .'
  '. input input .'
  '. button button .'
  '. info info .'
  '. info info .';
}
.activation__search-title {
  grid-area: title;
}
.activation__search-select {
  grid-area: select;
}
.activation__search-input {
  grid-area: input;
}
.activation__search-ready-button {
  grid-area: button;
}
.activation__search-patientinfo {
  grid-area: info;
}
