.addison-questions__text {
  text-align: left;
}
.addison-contacts__text {
  grid-area: title;
  text-align: center;
}

.emergency-contacts__yes-no-radio {
  display: 'block';
  height: '30px';
  line-height: '30px';
}

.wellbeing__question{
  margin-top: 5%;
}