
.general-info__education-dropdown{
  width: 100% !important;
}
.medical-history-side-button {
  margin-left: 2%;
  margin-bottom: 3%;
}

.medical-history-container {
  display: flex;
  flex-direction: row;
}