.pro-health__image {
    position: relative;
    width: 20%;
    height: 20%;
    @media(max-width: $tablet-breakpoint) {
     width: 50%;
     height: 20%; 
    };
  }
  
.emergency-icon__image {
  width: '100%';
  padding-top: '10%';
  max-width: '400px';
  @media only screen and (max-width: 900px) {
    padding-top: '8%';
   };
}
.test-signal__container{
  display: flex;
  align-items: center;
  flex-direction: column;
}