// .pro-health-or-addison__container {
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   grid-template-rows: 300px auto auto;
//   align-items: end;
//   grid-gap: 25px;
//   grid-template-areas:
//   '. title title .'
//   '. prohealth_button addison_button . '
//   '. . . .';
//   @media(max-width: $tablet-breakpoint) {
//     grid-template-areas: 
//     'title title title title'
//     'prohealth_button  prohealth_button prohealth_button prohealth_button'
//     'addison_button addison_button addison_button addison_button'
//   };
// }
// .sign-in__container {
//   display: grid;
//   grid-template-columns: 200px;
//   grid-template-rows: auto 70px auto
// }
// .activation__search-container {
//   display: grid;
//   grid-template-columns: 200px;
//   grid-template-rows: auto 70px auto
// }

// .addison__button {
//    grid-area: addison_button;
// }
// .addison-center__button {
//   grid-area: addison_button;
//   align-content: center;
// }
// .addison__input {
//   padding-bottom: 10px;
//   grid-area: addison_button;
// }
// .pro-health__button {
//   grid-area: prohealth_button
// }
// .pro-health-or-addison__title {
//   grid-area: title;
//   text-align: center;
// }

.addison-questions__text {
  text-align: left;
}
.addison-contacts__text {
  grid-area: title;
  text-align: center;
}

.emergency-contacts__yes-no-radio {
  display: 'block';
  height: '30px';
  line-height: '30px';
}



// .pro-health-button {
//   grid-column: 1;
//   grid-row: 3;
// }
// .addison-button {
//   grid-column: 1;
//   grid-row: 4;
// }

// .addison__select {
//   display: grid;
//   grid-template-columns: 200px;
//   grid-template-rows: auto 70px auto
// }