.pro-health-or-addison__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 300px auto auto;
  align-items: end;
  grid-gap: 25px;
  grid-template-areas:
    '. title title .'
    '. prohealth_button addison_button . '
    '. . . .';

  @media(max-width: $tablet-breakpoint) {
    grid-template-areas:
      'title title title title'
      'prohealth_button  prohealth_button prohealth_button prohealth_button'
      'addison_button addison_button addison_button addison_button'
  }

  ;
}

.addison__button {
  grid-area: addison_button;
}

.pro-health__button {
  grid-area: prohealth_button
}

.pro-health-or-addison__title {
  grid-area: title;
  text-align: center;
}