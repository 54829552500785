.question {
  display: flex;
  flex-direction: column;
}
.question__reminder{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input {
  margin-bottom: 5%;
  margin-top: .25%;
  display: flex;
  flex-direction: column;
}
.side-input-button {
  margin-left: 1%;
}
.medication-center-button {
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 3%;
  margin-top: 5%;
  height: 40px;
  @media (max-width: $mobile-breakpoint){
    margin-right: 12%;
  }
}
.medication-left-button {
  display: flex;
  flex-direction: row !important;
  text-align: center;
  align-items: center;
  height: 30px!important;
  width: 40%;
  margin-bottom: 5%;
  margin-right:70%;
  @media screen and (max-width: 1250px){
    width: 45%;
  }
  @media screen and (max-width: 1100px){
    width: 50%;
  }
  @media screen and (max-width: 1000px){
    width: 55%;
  }
  @media screen and (max-width: 900px){
    width: 30%;
  }
  @media screen and (max-width: 720px){
    width: 35%;
  }
  @media (max-width: $mobile-breakpoint){
    margin-right: 60%;
    width: 60%;
  }
}
.medication-remove-button {
  margin-left: 2%;
}
.medications-container {
  display: flex;
  flex-direction: column;
}
.medications__medication-inputs{
  width: 600px;
  @media (max-width: $mobile-breakpoint){
    width: 95%;
  }
}

.medications__reminder-buttons{
  display: flex;
  align-content: center;
  margin-left: 5%;
  margin-top: 3%;
  @media (max-width: $mobile-breakpoint){
    width: 95%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}

.medications__reminder-button-Add{
  height: 40px!important;
  width: 400px!important;
  margin-right: 5%;
  @media (max-width: $mobile-breakpoint){
    width: 70%!important;
    font-size: 16px!important;
    height: 30%!important;
    margin-bottom: 2%;
  }
}

.medications__vital-type{
  display: flex;
  flex-direction: column;
  width: 175px!important;
}

.medications__time-input{
  display: flex;
  flex-direction: column;
}

.medications__radio-group{
  flex-direction: row;
}