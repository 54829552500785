
.general-info__education-dropdown{
  width: 100% !important;
}

.general-health__minus-button{
  display: inline-block;
}

.general-health__plus-button{
  display: inline-block;
}

.general-health__substance-field{
  display: inline-block;
}

.general-health-side-button {
  margin-left: 2%;
  margin-bottom: 3%;
}
.general-health-center-button {
  align-items: left;
  margin-bottom: 5%;
}
.general-health-left-button {
  margin-left: 0%;
  margin-right: 59%;
  margin-bottom: 10%;
}
.general-health-container {
  display: flex;
  flex-direction: row;
}
.general-health__question-group{
  margin-bottom: 7%;
}

.general-health__radio-group{
  flex-direction: row;
}

.general_health__input{
    display: flex;
    flex-direction: column;
}