.Create-Addison-User__Layout{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top:8%;
   @media(max-width: $tablet-breakpoint){
    margin-top: 10%;
  }
  @media(max-width: $mobile-breakpoint){
    margin-top: 20%;
  }
}
.Create-Addison-User__Button{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-content: right;
}
.Create-Addison-User__Title{
  display: flex;
  align-items: left;
  flex-direction: column;
 
}
.Create-Addison-User__Oops{
  display: flex;
  align-items: left;
  flex-direction: column;
  @media(max-width: $tablet-breakpoint) {
    align-items: center;
    font-size: 25px !important;
    align-content: center;
    margin-left: auto;
    margin-right: auto; 
   };
}
.Create-Addison-User__image {
  position: relative;
  width: 12%;
  height: 12%;
  @media(max-width: $tablet-breakpoint) {
   width: 45%;
   height: 15%; 
  };
}